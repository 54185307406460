body {
  background: #f3f3f3 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
}

/* ScrollBar Stylings*/
.table-container {
  cursor: pointer;
  scrollbar-width: thin !important;
  scrollbar-color: black transparent !important;
}

.table-container::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important; /* The height is only seen on horizontal scrolling */
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}
